import './spinner.css'
export const LoadingComponent = () => {
  return (
    <>
      <div className="spinner-container">
        <div className="loading-spinner"></div>
      </div>
    </>
  );
};
