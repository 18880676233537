import { ReactNode } from "react"

export const NunitoH5Black = ({children}: {children: ReactNode}) => {
    return (
        <>
            <span style={styles.nunitoH5Black}>
                {children}
            </span>
        </>
    )
}

const styles = {
    nunitoH5Black: {
        fontSize: '28px',
        fontWeight: '700',
        fontFamily: 'Nunito Sans, sans-serif'
    }
}