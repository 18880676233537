import React, { useCallback, useEffect } from 'react';
import './App.css';import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Checkout } from './Components/Checkout';
import ErrorPage from './ErrorPage';
import { strings } from './infrastructure/localization';
import { OrderConfirmed } from './Components/OrderConfirmed';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const router = createBrowserRouter([
  {
    path: "/",
    Component: Checkout,
    errorElement: <ErrorPage />
  },
  {
    path: "/order-confirmed",
    Component: OrderConfirmed,
    errorElement: <ErrorPage />
  },
]);

const App = () => {
  const initLocalization = useCallback(async () => {
    const deviceLanguage = strings.getInterfaceLanguage().substring(0, 2);
    strings.setLanguage(deviceLanguage);
  }, []);


	useEffect(() => {
		initLocalization();
	}, [initLocalization]);
  
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
