export const PRICING = {
    QA: {
        lowEn: 'prctbl_1OUv63LbIHA3395VWkPdZMGV',
        lowEs: 'prctbl_1OVF3XLbIHA3395V75MkCX1a',
        high: 'prctbl_1OUsqGLbIHA3395Vw6UbGFTT',
    },
    PROD: {
        lowEn: 'prctbl_1OUvcwLbIHA3395VhaB4vJoc',
        lowEs: 'prctbl_1OVExuLbIHA3395VSmyRq722',
        high: 'prctbl_1OUvWlLbIHA3395VFPSejiFb',
    }
}
export const STRIPE_PUBLIC_KEY = {
    QA: 'pk_test_ThJrlOB3mM14Ny5yasx7EUKi00JxPvtGVw',
    PROD: 'pk_live_SoGsxlBONfALItyKq2RJFIgi00N60Qysw7'
}