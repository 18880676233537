import { strings } from "../../infrastructure/localization";
import { Img, ImgSize } from "../../ui/atoms/Img";
import envelope from "../../assets/img/email.svg";
import { NunitoH5Black } from "../../styles/textStyles/titles";
import { NunitoSansLightBlack } from "../../styles/textStyles/paragraph";
import { useEffect } from "react";
import { TrackingEvent, trackAmplitudeEvent } from "../../Utils/tracking";

export const OrderConfirmed = () => {
    const trackEvent = () => {
        try {
            trackAmplitudeEvent(TrackingEvent.Order, {})
        } catch (e) {
            console.log('Error ocurred while tracking: ', e)
        }
    }
    useEffect(() => {
        trackEvent()
    }, [])
  return (
    <>
      <div style={styles.container}>
        <div style={styles.header}>
          <Img
            imgSource={envelope}
            alt={strings.envelope}
            size={ImgSize.XLarge}
          />
          <NunitoH5Black>{strings.thanksForYourSubscription}</NunitoH5Black>
        </div>
        <div style={styles.message}>
          <NunitoSansLightBlack>
            {strings.successfullSubscriptionMessage}
          </NunitoSansLightBlack>
        </div>
      </div>
    </>
  );
};

const styles = {
    container: {
        display: 'flex',
        gap: 20,
        flexDirection: 'column',
        alignItems: 'center',
    },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  message: {
    width: "50%",
    textAlign: "center",
  },
} as const;
