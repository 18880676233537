import { useCallback, useEffect, useState } from "react"
import { LoadingComponent } from "../LoadingComponent";
import { GeoLocation, getUserLocation } from "../../Utils/location";
import { env } from "../../Utils/utils";
import { PRICING, STRIPE_PUBLIC_KEY } from "../../config";
import { TrackingEvent, trackAmplitudeEvent } from "../../Utils/tracking";

enum PricingTier {
    LowEn = 'lowEn',
    LowEs = 'lowEs',
    High = 'high',
}
const getPricingTier = ({continentCode, countryCode}: GeoLocation): PricingTier => {
    if (continentCode === 'EU' || continentCode === 'OC' || countryCode === 'US') {
        return PricingTier.High
    } else if (continentCode === 'SA' && countryCode !== 'BR') {
        return PricingTier.LowEs
    } else {
        return PricingTier.LowEn
    }
}
export const Checkout = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [pricingTable, setPricingTable]= useState('');

    const currentEnv = env()
    const stripePublickKey = STRIPE_PUBLIC_KEY[currentEnv]

    const calculatePricingTier = useCallback(async () => {
        const userLocation = await getUserLocation()
        const currentPricingTier = getPricingTier(userLocation);
        const envPricing = PRICING[currentEnv]
        setPricingTable(envPricing[currentPricingTier]);
        setIsLoading(false)
    }, [])

    const trackEvent = () => {
        try {
            trackAmplitudeEvent(TrackingEvent.Checkout, {})
        } catch (e) {
            console.log('Error ocurred while tracking: ', e)
        }
    }

    useEffect(() => {
        calculatePricingTier()
        trackEvent();
    }, [])
    
    if (isLoading) {
        return <LoadingComponent />
    }
    return (
        <stripe-pricing-table pricing-table-id={pricingTable}
        publishable-key={stripePublickKey}></stripe-pricing-table>
    )
}