import LocalizedStrings from 'react-localization';

type LocalizedStrings<T> = {
	[key: string]: string;
  } & T;

export const strings = new LocalizedStrings({
	//SPANISH TRANSLATIONS
	es: {
        thankYou: "Gracias",
        thanksForYourSubscription: "Gracias por tu subscripción!!",
        successfullSubscriptionMessage: "Felicitaciones! Gracias por elegir Scriptme Lite. \nTu clave de licencia está en camino a tu correo electrónico!",
        inquiresMessage: "Si tienes alguna duda o inconveniente, no dudes en contactarnos a hey@scriptme.io",
        envelope: "sobre",
	},

	// ENGLISH TRANSLATIONS
	en: {
        thankYou: "Thank you",
        thanksForYourSubscription: "Thanks for your Subscription!!",
        successfullSubscriptionMessage: "Congratulations! Thank you for choosing Scriptme Lite! \n Your license key is on its way to your email. Happy transcribing!",
        inquiresMessage: "If you have some inquires, or issues don't hesitate to send us an email to hey@scriptme.io",
        envelope: "envelope",
	},
});
