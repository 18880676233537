export enum ImgSize {
    Small= 'small',
    Medium = 'medium',
    Large = 'large',
    XLarge = 'xlarge',
}

type ImgProps = {
    imgSource: any;
    alt?: string;
    size?: ImgSize;
}
export const Img = ({imgSource, alt, size=ImgSize.Medium}: ImgProps) => {
    return (
        <img style={styles[size]} src={imgSource} alt={alt}/>
    )
}

const styles = {
    small: {
        width: 20,
        height: 20,
    },
    medium: {
        width: 60,
        height: 60,
    },
    large: {
        width: 80,
        height: 80,
    },
    xlarge: {
        width: 100,
        height: 100,
    }
}