//Import environment
import { env } from "./utils";
import * as amplitude from "@amplitude/analytics-browser";

// Types
export enum TrackingEvent {
  Checkout = "App Checkout",
  Order = "App Order",
}

const currentEnvironment = env();
const isProd = currentEnvironment === "PROD";

const trackStandardEvent = (
  event_category: string,
  event_label: string,
  event_value: number,
  event_json: any
) => {
  if (isProd) {
    //Track analytics
    //@ts-ignore
    gtag("event", event_label, {
      event_category: event_category,
      event_label: event_label,
      value: event_value,
    });

    //Track fb
    //@ts-ignore
    fbq("track", event_label, event_json);
  }
};

const trackAmplitudeEvent = (event: TrackingEvent, data: any) => {
  //If occurs in production
  console.log('Tracking event');
  if (isProd) {
    //Track amplitude
    amplitude.init("b3a32257ec63cdefe6f7462cba1d5ee7", {
        defaultTracking: {
            pageViews: false,
            sessions: false,
          }
    });
    amplitude.logEvent(event, data);
  }
};

export { trackStandardEvent, trackAmplitudeEvent };
