import { ReactNode } from "react";

export const NunitoSansLightBlack = ({children}: {children: ReactNode}) => {
    return (
        <>
            <span style={styles.nunitoSansLightBlack}>
                {children}
            </span>
        </>
    )
}

const styles = {
    nunitoSansLightBlack: {
        color: '#000000',
        fontFamily: 'Nunito Sans, sans-serif',
        fontSize: '16px',
        fontWeight: '400'
    }
}