export type GeoLocation = {
    continentCode: string;
    countryCode: string;
}
const getUserLocation = async (): Promise<GeoLocation> => {
  const API_KEY = "28fe7d54a514e136f90dcc4edccd0fe8c3be20f744f1b71c93704a8d";
  const response = await fetch(`https://api.ipdata.co/?api-key=${API_KEY}`)
  const {country_code, continent_code} = await response.json();
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  return {
    countryCode: country_code,
    continentCode: continent_code
  }
};
export {getUserLocation}